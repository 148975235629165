import React, { useMemo } from "react";

import { FORM_BUTTONS } from "../../../constants/ui";
import { Button, Props as ButtonProps } from "../Button";

export interface Props
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {
  text: string;
  btn_type: typeof FORM_BUTTONS[keyof typeof FORM_BUTTONS];
  disabled: boolean;
}

export const CustomizedFormButton: React.FC<Props> = ({
  text,
  btn_type = FORM_BUTTONS.PRIMARY,
  disabled = false,
  ...props
}: Props) => {
  const args = useMemo(() => {
    const commons = {
      label: text,
      disabled,
      textColor: "text-white",
      fontSize: "text-26",
      fontWeight: "font-mediumPlus",
      fontFamily: "font-quickStand",
      width: "w-full",
      ...props,
    };
    switch (btn_type) {
      case FORM_BUTTONS.DANGER:
        return {
          ...commons,
          backgroundColor: "bg-red-500",
          hoverColor: "hover:bg-red-800",
          height: "h-82",
        } as ButtonProps;
      case FORM_BUTTONS.SECONDARY:
        return {
          ...commons,
          backgroundColor: "bg-green-500",
          hoverColor: "hover:bg-green-800",
          height: "h-82",
        } as ButtonProps;
      case FORM_BUTTONS.BLUE:
        return {
          ...commons,
          backgroundColor: "bg-blue-600",
          hoverColor: "hover:bg-blue-800",
          height: "h-82",
        } as ButtonProps;
      default:
        return {
          ...commons,
          backgroundColor: "bg-green-500",
          hoverColor: "hover:bg-green-800",
          height: "h-82",
        } as ButtonProps;
    }
  }, [text, btn_type, disabled, props]);
  return (
    <>
      <Button {...args} />
    </>
  );
};
