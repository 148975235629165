import React, { useState, useEffect } from "react";

export const NotFound404 = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
      <div className="h-fit flex justify-center items-center flex-grow">
        <div
            className={`place-self-center text-center mb-32 transition-opacity duration-1000 ${
                isVisible ? "opacity-100" : "opacity-0"
            }`}
        >
          <h1 className="text-9xl font-bold text-gray-300 mb-4">404</h1>
        </div>
      </div>
  );
};
