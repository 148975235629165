import { useFormik } from "formik";
import React, {FC, useEffect} from "react";
import { connect } from "react-redux";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";

import { CustomizedLabelInput } from "../../components/core/inputs/CustomizedLabelInput";
import { INPUTS } from "../../constants/ui";
import { Dispatch, RootState } from "../../store/store";
import { RegisterPageWrapper } from "../RegisterPageWrapper";

type SignUpProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SignUpPage: FC<SignUpProps> = ({ isAuthenticated, signUp, isValidLink }) => {
  const navigate = useNavigate();
  const { inviteLink } = useParams();

  useEffect(() => {
    isValidLink(inviteLink || '').then( isValid => {
      if (!isValid) navigate("/*")
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      repeat_password: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      password: Yup.string()
        .min(6, "Minimum 6 characters required")
        .required("Required"),
      repeat_password: Yup.string().required("Passwords must match").oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: (values) => {
      signUp({
        email: values.email,
        companyLink: inviteLink!,
        firstName: values.first_name,
        lastName: values.last_name,
        password: values.password,
        repeatPassword: values.repeat_password
      });
    },
  });

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <RegisterPageWrapper
      title="Opret bruger"
      action={{
        title: "Opret bruger",
        handler: formik.handleSubmit,
        disabled: !(formik.isValid && formik.dirty),
      }}
    >
      <div className="flex items-center mb-3 justify-between space-x-10 ">
        <CustomizedLabelInput
          autoFocus
          id="first_name"
          type="text"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.errors.first_name}
          label="Fornavn"
          placeHolder="Dit fornavn..."
          styleType={formik.errors.first_name ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
        <CustomizedLabelInput
          id="last_name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.errors.last_name}
          label="Efternavn"
          placeHolder="Dit efternavn..."
          styleType={formik.errors.last_name ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
      </div>
      <div className="mb-6">
        <CustomizedLabelInput
          id="email"
          autoComplete="username"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
          label="Din e-mailadresse"
          placeHolder="Din e-mailadresse..."
          styleType={formik.errors.email ? INPUTS.ERROR : INPUTS.DEFAULT_FULL_WIDTH}
          disabled={false}
        />
      </div>
      <div className="flex items-center mb-3 justify-between space-x-10 ">
        <CustomizedLabelInput
          id="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
          label="Din adgangskode"
          placeHolder="Din adgangskode..."
          autoComplete="new-password"
          styleType={formik.errors.password ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
        <CustomizedLabelInput
          id="repeat_password"
          type="password"
          value={formik.values.repeat_password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.repeat_password}
          label="Bekræft adgangskode"
          autoComplete="new-password"
          placeHolder="Din adgangskode..."
          styleType={formik.errors.repeat_password ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
      </div>
    </RegisterPageWrapper>
  );
};

const mapState = (state: RootState) => ({
  isAuthenticated: state.user?.isAuthenticated,
});

const mapDispatch = (dispatch: Dispatch) => ({
  signUp: dispatch.user.signUp,
  isValidLink: dispatch.user.isValidLink,
});

export default connect(mapState, mapDispatch)(SignUpPage);
