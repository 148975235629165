import React, { FC } from "react";
import { connect } from "react-redux";

import smallPlusIcon from "../../assets/icons/small-plus.png";
import { getHexCardColorByType } from "../../helpers/bpLayers";
import { DashboardIcon } from "../core/icons/dashboard";
import { CardTag } from "./tag";
import { Dispatch, RootState } from "../../store/store";

interface ILayerCard {
  style: string;
  data: any;
  onAdd: any;
  isEditor: boolean;
  selectedActivities: string[];
  setActiveActivity?: any;
  removeActiveActivity?: any;
  toggledActivities: string[];
  setToggledActivity?: any;
  removeToggledActivity?: any;
}

const LayerCard: FC<ILayerCard> = ({ style, data, isEditor, onAdd, selectedActivities, setActiveActivity, removeActiveActivity, toggledActivities, setToggledActivity, removeToggledActivity }) => {
  const cardData = {...data, ...data.card_type}
  const color = getHexCardColorByType(cardData.type);
  const icon = DashboardIcon[cardData.type][cardData.position]
  const onAddOverwrited = isEditor ? onAdd : null;

  const toggleSelection = (selectedActivityIds: any) => {
    for (const id of selectedActivityIds) {
      if (toggledActivities.includes(id)) {
        removeToggledActivity([id]);
      }
      else {
        setToggledActivity([id]);
      }
    }
  };

  const renderTag = (tag: any) => {
    const selectedActivityIds = tag?.activities?.map((activity: any) => activity.id) || [];

    const isToggled = selectedActivityIds.some((id: any) => toggledActivities.includes(id));
    const isNotToggled = toggledActivities.length > 0;

    const isSelected = selectedActivityIds.some((id: any) => selectedActivities.includes(id));
    const isNotSelected = selectedActivities.length > 0;

    const test1 = isSelected || isToggled;
    const test2 = (isNotSelected || isNotToggled) && !isToggled && !isSelected;

    const onMouseEnter = () => setActiveActivity(selectedActivityIds);
    const onMouseLeave = () => removeActiveActivity(selectedActivityIds);

    return <CardTag isSelected={test1} isNotSelected={test2} open={onAddOverwrited} toggle={() => toggleSelection(selectedActivityIds)} key={tag.id} data={tag} cardData={data} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
    // return <CardTag isSelected={isSelected} isNotSelected={isNotSelected} open={onAddOverwrited} key={tag.id} data={tag} cardData={data} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
  }

  return (
    <div
      style={{background: color}}
      className={`box font-quickStand rounded-lg min-w-full min-h-full shadow-md ${style}`}
      >
      <div className="relative h-full">
        <div className="p-2 flex items-center justify-between">
          <h3 className="text-1xl font-bold">{cardData?.title || ''}</h3>
          {icon}
        </div>

        <div className="tags-wrap my-2 mx-0 flex pl-2 left-0 right-2 flex-wrap content-start top-10 bottom-10 overflow-x-hidden absolute">
          {cardData?.tags.map(renderTag)}
        </div>

        {isEditor && (
          <div className="text-1xl font-bold flex justify-end items-center absolute right-2 bottom-2">
            <p>Tilføj</p>
            <div
              onClick={onAdd.bind(null, { card_id: cardData.card_id, card_title: cardData.title, card_description: cardData.description })}
              className="ml-2 cursor-pointer w-9 h-9 flex items-center justify-center rounded-full bg-cardAddButton hover:bg-greenLayer transition-all duration-200 ease-in-out"
            >
              <img src={smallPlusIcon} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapState = (state: RootState) => ({
  isEditor: state.user.isEditor,
  toggledActivities: state.layer?.toggledActivities || [],
  selectedActivities: state.layer?.selectedActivities || [],
});

const mapDispatch = (dispatch: Dispatch) => ({
  setToggledActivity: dispatch.layer.setToggledActivity,
  removeToggledActivity: dispatch.layer.removeToggledActivity,
  setActiveActivity: dispatch.layer.setActiveActivity,
  removeActiveActivity: dispatch.layer.removeActiveActivity,
});

export default connect(mapState, mapDispatch)(LayerCard);
