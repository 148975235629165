import React, { FC } from "react";
import cogwheelIcon from "../../assets/icons/cogwheel.png";

interface ICardTag {
  data: any;
  open: any;
  toggle: any;
  cardData?: any;
  isSelected?: boolean;
  isNotSelected?: boolean;
  onMouseEnter?: (value: any) => void;
  onMouseLeave?: (value: any) => void;
}

export const CardTag: FC<ICardTag> = ({
  data,
  open,
  toggle,
  cardData,
  isSelected,
  isNotSelected,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={toggle}
        style={{ background: "#0000001c" }}
        className={`flex items-center text-1-xs overflow-hidden w-fit h-fit relative cursor-pointer px-3 p-1 pr-0 ml-0 mr-2 mb-3 text-white text-left rounded-[15px] shadow-sm transition-all duration-200 ease-in-out ${
          isSelected ? "shadow-[0px_0px_6px_rgba(0,0,0,0.6)]" : ""
        } ${isNotSelected ? "opacity-50" : ""}`}
      >
        {data?.activities?.length ? (
          <div className="flex justify-between h-1 absolute top-0 left-0 right-0">
            {data?.activities?.map((ac: any) => (
              <div
                key={ac.id}
                style={{ background: ac.color }}
                className="h-1 w-full"
              />
            )) || <div className="h-1 w-full" />}
          </div>
        ) : null}
        {data?.title || ""}
        {cardData ? (
          <div
            role="button"
            className="flex flex-col justify-between gap-0.5 group w-6 items-end pr-3"
            onClick={(e) => {
              e.stopPropagation();
              open?.({
                ...data,
                card_id: cardData.card_id,
                card_title: cardData.title,
                card_description: cardData.description,
              });
            }}
          >
            <div className="w-0.75 h-0.75 opacity-50 group-hover:opacity-100 bg-white rounded-10"></div>
            <div className="w-0.75 h-0.75 opacity-50 group-hover:opacity-100 bg-white rounded-10"></div>
            <div className="w-0.75 h-0.75 opacity-50 group-hover:opacity-100 bg-white rounded-10"></div>
          </div>
        ) : <div className="pr-3"></div>}
      </div>
      {/*<button*/}
      {/*  onClick={(e) => {*/}
      {/*    e.stopPropagation();*/}
      {/*    open?.({*/}
      {/*      ...data,*/}
      {/*      card_id: cardData.card_id,*/}
      {/*      card_title: cardData.title,*/}
      {/*      card_description: cardData.description,*/}
      {/*    });*/}
      {/*  }}*/}
      {/*  className="absolute bottom-1 left-10 p-1 cursor-pointer w-8 h-8"*/}
      {/*>*/}
      {/*  <img className="w-full h-full" src={cogwheelIcon} />*/}
      {/*</button>*/}
    </div>
  );
};

export const RedLayerTag: FC<any> = ({ title }) => {
  return (
    <div
      style={{ background: "#0000001c" }}
      className={`text-1-xs overflow-hidden w-fit relative cursor-pointer px-3 p-1 ml-0 m-1 text-white text-left rounded-[15px] shadow-sm`}
    >
      {title || ""}
    </div>
  );
};
