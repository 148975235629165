import { useFormik } from "formik";
import React, { FC } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { CustomizedLabelInput } from "../../components/core/inputs/CustomizedLabelInput";
import { INPUTS } from "../../constants/ui";
import { Dispatch } from "../../store/store";
import { RegisterPageWrapper } from "../RegisterPageWrapper";

type IPasswordValues = Record<string, string>;

type IForgotPasswordPage = ReturnType<typeof mapDispatch>;
const ForgotPasswordPage: FC<IForgotPasswordPage> = ({ forgotPassword }) => {
  const passwordValues: IPasswordValues = {
    email: "",
  };

  const formik = useFormik({
    initialValues: passwordValues,
    validationSchema: Yup.object({
      email: Yup.string().email().required("Påkrævet"),
    }),
    onSubmit: (values) => {
      forgotPassword({
        username: values.email,
      });
    },
  });

  return (
    <RegisterPageWrapper
      title="Nulstil adgangskode"
      action={{
        title: "Nulstil",
        handler: formik.submitForm,
        disabled: !(formik.isValid && formik.dirty),
      }}
    >
      <div className="space-y-4">
        <p className="text-lg text-gray-700">
          Indtast din emailadresse for at nulstille din adgangskode.
        </p>
        <p className="text-lg text-gray-700">
          Du vil modtage en email med din nye adgangskode. Husk eventuelt at
          tjekke din spam-mappe, og at skifte din adgangskode efterfølgende.
        </p>
      </div>
      <div className="mt-6">
        <CustomizedLabelInput
          autoFocus
          id="email"
          label="Email"
          value={formik.values.email}
          placeHolder="Din mailadresse..."
          onChange={formik.handleChange}
          type="email"
          error={formik.values.email && formik.errors.email}
          styleType={
            formik.values.email && formik.errors.email
              ? INPUTS.ERROR
              : INPUTS.DEFAULT_FULL_WIDTH
          }
          disabled={false}
          className="w-full mt-2 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </RegisterPageWrapper>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({
  forgotPassword: dispatch.user.forgotPassword,
});

export default connect(null, mapDispatch)(ForgotPasswordPage);
