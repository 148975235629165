import {getIn, useFormik} from "formik";
import React, { FC } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { CustomizedLabelInput } from "../../components/core/inputs/CustomizedLabelInput";
import { INPUTS, SELECT_TYPES } from "../../constants/ui";
import { Dispatch, RootState } from "../../store/store";
import { RegisterPageWrapper } from "../RegisterPageWrapper";
import { CustomizedLabelSelect } from "../../components/core/select/CustomizedLabelSelect";

type ISignUpCompanyPage = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

const SignUpCompanyPage: FC<ISignUpCompanyPage> = ({
  isAuthenticated,
  signUpCompany,
}) => {
  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    company_name: Yup.string().required("Required"),
    vat_number: Yup.string().required("Required"),
    password: Yup.string()
        .min(6, "Minimum 6 characters required")
        .required("Required"),
    repeat_password: Yup.string()
        .required("Passwords must match")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    business_network: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      company_name: "",
      vat_number: "",
      password: "",
      repeat_password: "",
      business_network: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      await signUpCompany({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        company_name: values.company_name,
        vat_number: values.vat_number,
        password: values.password,
        repeat_password: values.repeat_password,
        business_network: values.business_network,
      });
    },
  });

  const isFieldRequired = (field: string) => {
    const fieldSchema = (validationSchema.fields as Record<string, Yup.BaseSchema>)[field];
    if (fieldSchema && fieldSchema.tests) {
      return fieldSchema.tests.some(test => test.OPTIONS.name === 'required');
    }
    return false;
  };

  const handleSelectChange = (selectedOption: any) => {
    formik.setFieldValue("business_network", selectedOption.value);
  };

  return (
    <RegisterPageWrapper
      title="Opret virksomhed"
      action={{
        title: "Opret bruger",
        handler: formik.handleSubmit,
        disabled: !(formik.isValid && formik.dirty),
      }}
    >
      <div className="flex items-center mb-3 justify-between space-x-10 ">
        <CustomizedLabelInput
            autoFocus
            id="first_name"
            type="text"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.first_name && formik.errors.first_name ? formik.errors.first_name : undefined}
            label={
              <>
                <span>Fornavn</span>
                {isFieldRequired("first_name") && <span className="text-red-500">*</span>}
              </>
            }
            placeHolder="Dit fornavn..."
            styleType={formik.touched.first_name && formik.errors.first_name ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
            disabled={false}
        />
        {/*{formik.touched.first_name && formik.errors.first_name && (*/}
        {/*    <div className="text-red-500 text-sm">{formik.errors.first_name}</div>*/}
        {/*)}*/}
        <CustomizedLabelInput
          id="last_name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.values.last_name && formik.errors.last_name}
          label={
            <>
              <span>Efternavn</span>
              {isFieldRequired("last_name") && <span className="text-red-500">*</span>}
            </>
          }
          placeHolder="Dit efternavn..."
          styleType={formik.values.last_name && formik.errors.last_name ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
      </div>
      <div className="flex items-center mb-3 justify-between space-x-10 ">
        <CustomizedLabelInput
          id="company_name"
          value={formik.values.company_name}
          onChange={formik.handleChange}
          error={formik.values.company_name && formik.errors.company_name}
          label={
            <>
              <span>Virksomhed</span>
              {isFieldRequired("company_name") && <span className="text-red-500">*</span>}
            </>
          }
          placeHolder="Virksomhedsnavn..."
          styleType={formik.values.company_name && formik.errors.company_name ? INPUTS?.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
        <CustomizedLabelInput
          id="vat_number"
          value={formik.values.vat_number}
          onChange={formik.handleChange}
          error={formik.values.vat_number && formik.errors.vat_number}
          label={
            <>
              <span>CVR-nummer</span>
              {isFieldRequired("vat_number") && <span className="text-red-500">*</span>}
            </>
          }
          placeHolder="CVR-nummer..."
          styleType={formik.values.vat_number && formik.errors.vat_number ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
      </div>
      <div className="mb-6">
        <CustomizedLabelInput
          id="email"
          autoComplete="username"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.values.email && formik.errors.email}
          label={
            <>
              <span>Din e-mailadresse</span>
              {isFieldRequired("email") && <span className="text-red-500">*</span>}
            </>
          }
          placeHolder="Din e-mailadresse..."
          styleType={formik.values.email && formik.errors.email ? INPUTS.ERROR : INPUTS.DEFAULT_FULL_WIDTH}
          disabled={false}
        />
      </div>
      <div className="flex items-center mb-3 justify-between space-x-10 ">
        <CustomizedLabelInput
          id="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.values.password && formik.errors.password}
          label={
            <>
              <span>Din adgangskode</span>
              {isFieldRequired("password") && <span className="text-red-500">*</span>}
            </>
          }
          placeHolder="Din adgangskode..."
          autoComplete="new-password"
          styleType={formik.values.password && formik.errors.password ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
        <CustomizedLabelInput
          id="repeat_password"
          type="password"
          value={formik.values.repeat_password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.values.repeat_password && formik.errors.repeat_password}
          label={
            <>
              <span>Bekræft adgangskode</span>
              {isFieldRequired("repeat_password") && <span className="text-red-500">*</span>}
            </>
          }
          autoComplete="new-password"
          placeHolder="Din adgangskode..."
          styleType={formik.values.repeat_password && formik.errors.repeat_password ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
      </div>
      <div className="mb-6">
        <CustomizedLabelSelect
          type={SELECT_TYPES.GRAY}
          optionList={options}
          error={formik.values.business_network && formik.errors.business_network}
          onChange={handleSelectChange}
          isMulti={false}
          label={
            <>
              <span>Erhvervsnetværk</span>
              {isFieldRequired("business_network") && <span className="text-red-500">*</span>}
            </>
          }
          placeHolder="Vælg erhvervsnetværk..."
        />
      </div>
    </RegisterPageWrapper>
  );
};

const options = [
  { value: "Hedensted", label: "Hedensted Erhverv", color: "#5AB478" },
  { value: "Billund", label: "Billund Erhverv", color: "#2196F3" },
];

const mapState = (state: RootState) => ({
  isAuthenticated: state.user?.isAuthenticated,
});

const mapDispatch = (dispatch: Dispatch) => ({
  signUpCompany: dispatch.user.signUpCompany,
});

export default connect(mapState, mapDispatch)(SignUpCompanyPage);
