import React, {FC, useEffect, useState} from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";

import {
  BUSINESS_PLAN_ROUTE, EMPTY_BUSINESS_PLAN_ROUTE,
  SIGN_IN_ROUTE,
} from "../../store/constants/route-constants";
import { Dispatch, RootState } from "../../store/store";
import {NotFound404} from "../../components/404";

const HomePage: FC<any> = ({ isAuth, businessPlans, getBPs }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuth) {
        await getBPs();
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuth, getBPs]);

  useEffect(() => {
    if (!loading) {
      if (businessPlans.length > 0) {
        const firstBusinessPlanId = businessPlans[0].id;
        navigate(`${BUSINESS_PLAN_ROUTE}/${firstBusinessPlanId}`);
      } else {
        navigate(EMPTY_BUSINESS_PLAN_ROUTE);
        console.error("Failed to fetch business plans");
      }
    }
  }, [loading, businessPlans, navigate]);

  if (!isAuth) return <Navigate to={SIGN_IN_ROUTE} />;

  return <NotFound404 />;
};

const mapState = (state: RootState) => ({
  isAuth: state.user.isAuthenticated,
  businessPlans: state.businessPlan,
});

const mapDispatch = (dispatch: Dispatch) => ({
  getBPs: dispatch.businessPlan.getBPs,
});

export default connect(mapState, mapDispatch)(HomePage);