import { createModel } from "@rematch/core";
import { RootModel } from ".";
import http from "../http/http-common";
import { toast } from "react-toastify";
import {APPLICATION_LAYER_URL, REQUEST_PERMISSION_FOR_LAYER_URL} from "../constants/api-contstants";
import history from "../../helpers/history";
import { ILayerState } from "./interfaces/layer";

export const layer = createModel<RootModel>()({
  state: {} as ILayerState,
  reducers: {
    setLayer(state, data) {
      return { ...data };
    },
    setLayerActivity(state, data) {
      const oldActivities = state?.activities || [];
      return { ...state, activities: [...oldActivities, data] };
    },
    deleteLayerActivity(state, data) {
      return { ...state, activities: [...state.activities.filter(i => i.id !== data)] };
    },
    updateLayerActivity(state, data) {
      return { ...state, activities: [...state.activities.map(i => i.id === data.id ? data : i)] };
    },
    addTag(state, data) {
       state.cards.forEach((card) => {
        if (card.card_id === data.card) card.tags.push(data);
       });
      return {...state};
    },
    updateTag(state, data) {
      state.cards.forEach((card) => {
       if (card.card_id === data.card_id) card.tags = card.tags.map((i: any) => i.id === data.id ? data: i);
      });
     return {...state};
   },
    deleteTagFromState(state, data) {
      state.cards.forEach((card) => {
        if (card.card_id === data.card_id) card.tags = card.tags.filter((i: any) => i.id !== data.id);
       });
      return {...state};
    },
    setActiveActivity(state, data) {
      const selectedActivities = state?.selectedActivities || []
      return { ...state, selectedActivities: [...selectedActivities, ...data]}
    },
    removeActiveActivity(state, data) {
      const selectedActivities = state?.selectedActivities?.filter((i: string) => !data.includes(i)) || []
      return { ...state, selectedActivities}
    },
    setToggledActivity(state, data) {
      const toggledActivities = state?.toggledActivities || []
      return { ...state, toggledActivities: [...toggledActivities, ...data]}
    },
    removeToggledActivity(state, data) {
      const toggledActivities = state?.toggledActivities?.filter((i: string) => !data.includes(i)) || []
      return { ...state, toggledActivities}
    },
    setPermissionLayerType(state, data) {
      return { ...state, permissionLayerType: data.type}
    },
    setDeletedLayer(state, data) {
      return { ...state, deletedLayer: { id: data.id, type: data.type }}
    },
    setDeletedLayerTag(state, data) {
      return { ...state, deletedLayerTag: data}
    },
    setDeletedActivityId(state, data) {
      return { ...state, deletedActivityId: data.id}
    },
  },
  effects: (dispatch) => ({
    async getLayer(layerId) {
      const request = await http.get(`${APPLICATION_LAYER_URL}/${layerId}`);
      if (request.status === 200) {
        dispatch.layer.setLayer(request.data);
        if (request?.data?.id) return true;
      }
      return false;
    },
    async createLayer(data) {
      const request = await http.post(APPLICATION_LAYER_URL, data);
      if (request.status === 201) {
        dispatch.layer.setLayer(request.data);
        toast.success("Lag tilføjet!");
        dispatch.businessPlan.setBusinessPlanLayer({...data, layer: request.data});
        history.push(history.location.pathname + "/layer/" + request.data.id);
      }
    },
    async deleteLayer(id) {
      const request = await http.delete(APPLICATION_LAYER_URL, {
        data: { id, layer_id: id }
      });
      if (request.status === 200) {
        dispatch.businessPlan.getBPs();
        toast.success("Lag slettet!");
      }
    },
    async saveActivity(data) {
      const request = await http.post(`${APPLICATION_LAYER_URL}/activity`, data);
      if (request.status === 201) {
        if (data.id) {
          dispatch.layer.updateLayerActivity(data);
          toast.success("Aktivitet opdateret!");
        } else {
          dispatch.layer.setLayerActivity(request.data);
          toast.success("Aktivitet tilføjet!");
        }
      }
      if (data?.layer_id) this.getLayer(data.layer_id);
      if (request.request.status === 400) {
        const response = JSON.parse(request.request.response);
        toast.error(response?.message || '');
      }
    },
    async deleteActivity(id: string, state) {
      const request = await http.delete(`${APPLICATION_LAYER_URL}/activity`, {
        data: { id }
      });
      if (request.status === 200) {
        dispatch.layer.deleteLayerActivity(id);
        if (state.layer.id) this.getLayer(state.layer.id);
        toast.success("Aktivitet slettet!");
      }
    },
    async createTag(data) {
      const request = await http.post(`${APPLICATION_LAYER_URL}/tag`, data);
      if (request.status === 201) {
        if (data.id) {
          dispatch.layer.updateTag({...request.data, card_id: data.card_id});
          toast.success("Aktivitet opdateret!");
        } else {
          dispatch.layer.addTag(request.data);
          toast.success("Tag tilføjet!");
        }
      }
    },
    async deleteTag(data) {
      const request = await http.delete(`${APPLICATION_LAYER_URL}/tag`, {
        data: { id: data.id }
      });
      if (request.status === 200) {
        dispatch.layer.deleteTagFromState(data);
        toast.success("Tag slettet!");
      }
    },
    async getTags(layerId: string) {
      const request = await http.get(`${APPLICATION_LAYER_URL}/tag/` + layerId);
      if (request.status === 200) {
        return request.data;
      }
    },

    async savePermissionLayerType(layerType: string) {
      dispatch.layer.setPermissionLayerType({type: layerType})
    },

    async saveDeletedLayer({ layerId, layerType }) {
      dispatch.layer.setDeletedLayer({id: layerId, type: layerType})
    },

    async saveDeletedLayerTag(tag) {
      dispatch.layer.setDeletedLayerTag(tag)
    },

    async sendPermissionRequest({ businessPlanId, layerType }) {
      const data = {
        bpId: businessPlanId,
        layerType
      }
      const request = await http.post(REQUEST_PERMISSION_FOR_LAYER_URL, data);
      if (request.status === 201) {
        toast.success("Anmodning er sendt!");
      }
    },

    async saveDeletedActivityId(activityId: string) {
      dispatch.layer.setDeletedActivityId({id: activityId})
    }
  }),
});
