import React, { FC, ReactElement } from "react";

interface ILayerActivity {
  title: string;
  color?: string;
  extraClassNames?: string;
  icon?: ReactElement;
  toggle?: () => void;
  open?: () => void;
  isSelected?: boolean;
  isNotSelected?: boolean;
  onMouseEnter?: (value: any) => void;
  onMouseLeave?: (value: any) => void;
}

export const LayerActivity: FC<ILayerActivity> = ({
  title,
  color,
  extraClassNames,
  icon,
  toggle,
  open,
  isSelected,
  isNotSelected,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
      <button
          onClick={toggle ?? open}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={color ? {backgroundColor: color} : undefined}
          className={`ml-3 px-4 py-2 font-semibold text-sm text-white rounded-full shadow-sm flex items-center transition-all duration-200 ease-in-out ${
              isSelected ? "shadow-[0px_0px_6px_rgba(0,0,0,0.6)]" : ""
          } ${
              isNotSelected ? "opacity-25" : ""
          } ${extraClassNames}`}
      >
        {title} {icon}
        {toggle && (
            <div
                role="button"
                className="flex flex-col justify-between gap-0.5 group w-3 items-end"
                onClick={(e) => {
                  e.stopPropagation();
                  open?.();
                }}
            >
              <div className="w-0.75 h-0.75 opacity-50 group-hover:opacity-100 bg-white rounded-10"></div>
              <div className="w-0.75 h-0.75 opacity-50 group-hover:opacity-100 bg-white rounded-10"></div>
              <div className="w-0.75 h-0.75 opacity-50 group-hover:opacity-100 bg-white rounded-10"></div>
            </div>
        )}
      </button>
  );
};
