import React, { SyntheticEvent, useEffect, useState } from "react";

import { INPUTS } from "../../../../constants/ui";
import { Button } from "../../Button";
import { CustomizedTextarea } from "../../textarea/CustomizedTextarea";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../../store/store";
import { EDITOR_ROLE, READER_ROLE } from "../../../../constants/roles";
import { CustomizedLabelInput } from "../../inputs/CustomizedLabelInput";
import validateEmail from "../../../../helpers/emailValidator";
import {toast} from "react-toastify";

export interface Props {
  closeModal: string;
  rightTitle: string;
  closeModalTwo:  (e: SyntheticEvent) => void;
  body: string;
  rightInputLabel: string;
  greenBtnLabel: string;
  submitDelete: () => void;
  cancel: () => void;
}

const ROOT_URL = window.location.origin + '/invite/';

export const RightComponent: React.FC<Props> = (
  {
    rightTitle,
    closeModalTwo,
    rightInputLabel,
    closeModal,
    ...props
  }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const userState = useSelector((state: RootState) => state.user);
  const links = userState?.links || [];
  const [url, setUrl] = useState(links.find(e => e.role.value === EDITOR_ROLE)?.link || '');
  const [email, setEmail] = useState('');

  useEffect(() => {
    dispatch.user.getOwner().then((id) => {
      if (id) {
        dispatch.user.getCompanySharedLinks(id).then((li: any[]) => {
          setUrl(getURLbyRole(EDITOR_ROLE, li))
        })
      }
    });
  }, []);

  const [isActive, setIsActive] = useState(true);
  const generateUrl = () => {
    let link = null;
    if (!isActive) {
      link = getURLbyRole(EDITOR_ROLE, links)
    } else {
      link = getURLbyRole(READER_ROLE, links)
    }
    return link;
  }

  const getURLbyRole = (role: string, urls: any[]) => {
    return ROOT_URL + urls?.find(e => e.role.value === role)?.link || ''
  }

  const handleSwitchButtonOnClick = () => {
    setIsActive(!isActive);
    setUrl(generateUrl());
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const sendLinkViaEmail = () => {
    if (validateEmail(email)) {
      const data = {
        email,
        link: url,
      }
      dispatch.user.sendLinkViaEmail(data)
    } else {
      toast.error("Ukorrekt email format!")
    }
  }

  const activeClass = classNames({
    "bg-green-200": isActive,
    "text-white": isActive
  });

  const inActiveClass = classNames({
    "bg-green-200": !isActive,
    "text-white": !isActive
  });

  return (
    <div className="flex-initial py-42 px-42 h-full rounded-r-lg">
      <div>
        <h3 className="font-quickStand font-semibold text-[2.3rem]">{rightTitle}</h3>
        <div className="my-2">
          <div className="w-full">
            <div className="flex items-center justify-between">
              <label className="font-quickStand font-mediumPlus Plus text-[1.5rem]">{rightInputLabel}</label>
              <div onClick={handleSwitchButtonOnClick} className="my-2 flex w-62 justify-between bg-gray-200 rounded-xl">
                <div className={`${activeClass} cursor-pointer px-4 py-2 rounded-xl`}>Administrator</div>
                <div className={`${inActiveClass} cursor-pointer px-4 py-2 rounded-xl`}>Læsning</div>
              </div>
            </div>
            <CustomizedTextarea
              value={url || ""}
              readOnly
              height="h-[130px]"
              placeHolder={url}
              disabled={false}
              type={INPUTS.DEFAULT_HALF_WIDTH}
            />
          </div>
        </div>

        <div className="flex items-center w-full">
          <div className="w-3/4">
            <CustomizedLabelInput
              fontsizestyle="text-[1.5rem]"
              label={"Send via email"}
              value={email}
              onChange={handleEmailChange}
              placeHolder={"email"}
              styleType={INPUTS.MEDIUM_BLACK}
              disabled={false}
            />
          </div>
          <div className="w-1/4 ml-2 mt-7">
            <Button
              label="Send"
              backgroundColor="bg-darkViolet"
              hoverColor="hover:bg-darkVioletHover"
              textColor="text-white"
              height="h-82"
              width="w-full"
              fontSize="text-[1.3rem]"
              disabled={false}
              onClick={sendLinkViaEmail}
            />
          </div>
        </div>

        <div className="mt-2">
          <Button
            label="Luk"
            backgroundColor="bg-green-200"
            hoverColor="hover:bg-green-800"
            textColor="text-white"
            height="h-82"
            width="w-full"
            fontSize="text-[1.3rem]"
            disabled={false}
            onClick={closeModalTwo}
          />
        </div>
      </div>
    </div>
  );
};
