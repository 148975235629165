export const FORM_BUTTONS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DANGER: "danger",
  BLUE: "blue",
} as const;

export const LAYOUT_BUTTONS = {
  DEEP_KOAMARU: "deep_koamaru",
  RED: "red",
  YELLOW: "yellow",
  GREEN: "green",
  GRAY: "gray",
} as const;

export const INPUTS = {
  DEFAULT_FULL_WIDTH: "default_full_width",
  DEFAULT_HALF_WIDTH: "default_half_width",
  MEDIUM_LIGHT_BLUE: "medium_light_blue",
  MEDIUM_BLACK: "medium_black",
  MEDIUM_LIGHT_YELLOW: "medium_light_yellow",
  MEDIUM_LIGHT_GREEN: "medium_light_green",
  ERROR: "error",
} as const;

export const SELECT_TYPES = {
  LIGHT_BLUE: "pacific_blue",
  YELLOW: "yellow",
  GREEN: "green",
  GRAY: "gray",
} as const;

export const DASHBOARD_ICON_TYPES = {
  BLUE: "blue",
  YELLOW: "yellow",
  GREEN: "green",
} as const;

export const OPTION_COLOR = '#322864'
