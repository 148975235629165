import PropTypes from "prop-types";
import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useRoutes, useMatch } from "react-router";

import layerIcon from "../../assets/icons/layers.png";
import shareIcon from "../../assets/icons/share.png";
import userIcon from "../../assets/icons/user.png";
import reportIcon from "../../assets/icons/report.png";
import logo from "../../assets/images/logo.png";
import { Dispatch, RootState } from "../../store/store";
import { SHARE_ACCESS_MODAL_DATA } from "../../store/constants/share-constants";
import { ShareAccessModal } from "../core/modals/share-access-modal";
import { ReportModal } from "../core/modals/reportModal";
import { Link } from "react-router-dom";
import { HOME_ROUTE, PROFILE_ROUTE } from "../../store/constants/route-constants";
import routes from "../../routes";
import classNames from "classnames";

import { REPORT_MODAL_DATA } from "../../store/constants/report-constants";

type HeaderPageProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

const Header: FC<HeaderPageProps> = ({
  userId,
  isAuthenticated,
  isOwner,
  isAdmin,
  getOwner,
  layer
}) => {
  const navigate = useNavigate();
  const routeObj = useRoutes(routes);
  const [activityModal, setActivityModal] = useState<any>({ visible: false });
  const [reportModal, setReportModal] = useState<any>({ visible: false });

  const openActivityModal = () => { setActivityModal({ visible: true }); }
  const closeActivityModal = () => { setActivityModal({ visible: false }); }

  const openReportModal = () => { setReportModal({ visible: true }); }
  const closeReportModal = () => { setReportModal({ visible: false }); }
  const isBpPage = window.location.href.includes('/business-plan/');

  const icons = [
    {
      id: 1,
      text: "Lag",
      icon: layerIcon,
      onClick: null, to: () => {
        if (location.href.includes('layer')) {
          return `/${location.href.split('/').slice(3, 5).join('/')}`;
        }
        return HOME_ROUTE;
      }
    },
    { id: 2, text: "Profil", icon: userIcon, onClick: null, to: () => PROFILE_ROUTE },
  ];

  useEffect(() => {
    if (isAuthenticated) getOwner()
  }, [userId]);

  const renderNavItems = (item: any) => {
    return (
      <div
        key={item.id}
        onClick={() => navigate(item.to())}
        className="text-center cursor-pointer w-24"
      >
        <img className="m-auto" src={item.icon} />
        <p className="text-lg font-semibold leading-6">{item.text}</p>
      </div>
    );
  };

  const getPageTitle = () => {
    let title = "Forretningsplan";
    if (routeObj === null) title = "404 fejl";
    const param = routeObj?.props?.value?.matches[0]?.params.layer_id || "";
    if (param === layer?.id) return title = layer.title;
    else title = routeObj?.props?.value?.matches[0]?.route?.pageTitle || "Forretningsplan";
    if (title) return title;
  }

  const buttonWidth = classNames({
    "w-64": isAdmin,
    "w-44": !isAdmin,
  });

  if (isAuthenticated)
    return (
      <div className="w-full grid grid-cols-3 items-center justify-center px-5 pt-4">
        <div className="w-full whitespace-pre">
          <Link className="text-4xl font-bold cursor-pointer" to={HOME_ROUTE}>{getPageTitle()}</Link>
          {/*<div className="text-4xl font-bold">{getPageTitle()}</div>*/}
        </div>
        <div className={`flex justify-center items-center w-full animate-fadeIn`}>
          {icons.map(renderNavItems)}
          {isOwner &&
            <div onClick={openActivityModal} className="text-center cursor-pointer w-24">
              <img className="m-auto" src={shareIcon} />
              <p className="text-lg font-semibold leading-6">Del</p>
            </div>}
          {isBpPage ? <div onClick={openReportModal} className="text-center cursor-pointer w-24">
            <img className="m-auto" src={reportIcon} />
            <p className="text-lg font-semibold leading-6">Rapport</p>
          </div> : null}
          {isAdmin &&
            <a
              href={process.env.REACT_APP_ADMIN_URL}
              target="_blank"
              className="text-center cursor-pointer bg-gray-400 hover:bg-gray-800 text-white py-2 mx-4 px-4 rounded">
              Admin
            </a>
          }
        </div>
        <div className="w-full flex justify-end">
          <img className="w-16" src={logo} />
        </div>
        {activityModal?.visible && isOwner ?
          <ShareAccessModal
            setShowModal={closeActivityModal}
            showModal={activityModal.visible}
            leftTitle={SHARE_ACCESS_MODAL_DATA.leftTitle}
            rightTitle={SHARE_ACCESS_MODAL_DATA.rightTitle}
            closeModalTwo={closeActivityModal}
            leftInputLabelOne={SHARE_ACCESS_MODAL_DATA.leftInputLabelOne}
            leftInputLabelTwo={SHARE_ACCESS_MODAL_DATA.leftInputLabelTwo}
            rightInputLabel={SHARE_ACCESS_MODAL_DATA.rightInputLabel}
            body={SHARE_ACCESS_MODAL_DATA.introduction}
          />
          : null}
        {(reportModal?.visible) ? <ReportModal
          setShowModal={closeReportModal}
          closeModal={closeReportModal}
          showModal={reportModal.visible}
          leftTitle={REPORT_MODAL_DATA.leftTitle}
          rightTitle={REPORT_MODAL_DATA.rightTitle}
          leftInputLabelOne={REPORT_MODAL_DATA.leftInputLabelOne}
          leftInputLabelTwo={REPORT_MODAL_DATA.leftInputLabelTwo}
          rightInputLabel={REPORT_MODAL_DATA.rightInputLabel}
          body={REPORT_MODAL_DATA.body}
          rightBody={REPORT_MODAL_DATA.rightBody}
        /> : null}
      </div>
    );
  return <></>;
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapState = (state: RootState) => ({
  isAuthenticated: state.user.isAuthenticated,
  isOwner: state.user.isOwner,
  isAdmin: state.user?.isAdmin,
  userId: state.user.data?.id,
  layer: state.layer
});

const mapDispatch = (dispatch: Dispatch) => ({
  signOut: dispatch.user.signOut,
  fullSignOut: dispatch.user.fullSignOut,
  getOwner: dispatch.user.getOwner
});

export default connect(mapState, mapDispatch)(Header);
