import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { RedLayerIcons } from "../../components/core/icons";
import { Dispatch, RootState } from "../../store/store";
import GoalCategoryDetailModal from "../../components/RedLayerModals/goalCategoryDetailModal";
import { GoalModal } from "../../components/RedLayerModals/GoalModal";
import RedLayerGoal from "../../components/RedLayerModals/redLayerGoal";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import { RED_LAYER_DESCRIPTION } from "../../store/constants/layer-constants";
import { NotFound404 } from "../../components/404";
import "./style.css";
import {
  LAYER_DELETE_CONFIRMATION_DATA,
  RED_SUB_GOAL_DELETE_CONFIRMATION_DATA
} from "../../store/constants/bp-constants";
import {ConfirmationModal} from "../../components/core/modals/confirmation-modal";

const RedLayerPage: FC<any> = ({
  userId, redLayer, getRedLayer, saveLayerGoal, deleteLayerGoal, saveDeletedLayerGoalId, deletedLayerGoalId
}) => {
  const { layer_id } = useParams();
  const [showFullDescription, setShowFullDescription] = useState<any>(false);
  const [categoryModal, setCategoryModal] = useState<any>({});
  const [goalModla, setGoalModal] = useState<any>({
    visible: false,
    data: null
  });
  const [show404, setShow404] = useState(false);
  const [showDeleteLayerGoalModal, setShowDeleteLayerGoalModal] = useState(false);

  useEffect(() => {
    getRedLayer(layer_id).then((res: boolean) => {
      setShow404(!res);
    });
  }, [userId]);

  const onSelect = (item: any) => {
    if (item.id === categoryModal.id) setCategoryModal({});
    else setCategoryModal(item)
  }

  const openGoalModal = (data: any) => {
    setGoalModal({ visible: true, data });
  }
  const closeGoalModal = () => {
    setGoalModal({ ...goalModla, visible: false });
  }

  const onSubmitGoalModal = async (event: any) => {
    event.preventDefault();
    const goalsWrap = [...event.target.offsetParent.querySelector('.goal-wrap')?.childNodes];
    const title = event.target.elements[0]?.value;
    const milestoneDefinition = event.target.elements[1]?.value;
    const description = event.target.elements[2]?.value;
    const deadline = event.target.elements[3]?.valueAsDate.toISOString();
    const responsibles = event.target.elements[4]?.value;
    const goals = goalsWrap.map((g: any) => g.dataset?.selected === "1" ? g.dataset.id : false).filter(Boolean);

    if (!title?.length) {
      toast.warning("Indtast overskrift!");
      return;
    }
    if (!goals?.length) {
      toast.warning("Vælg mål!");
      return;
    }
    const layerGoal: any = {
      title,
      milestoneDefinition,
      description,
      goals,
      deadline,
      responsibles,
      categoryId: goalModla.data.categoryId || goalModla.data.id,
      layer: redLayer.id,
    };
    if (goalModla.data?.layerGoalId) layerGoal.id = goalModla.data?.layerGoalId
    await saveLayerGoal(layerGoal);
    closeGoalModal();
  }

  const onDeleteLayerGoal = (layerGoalId: string) => {
    setShowDeleteLayerGoalModal(true)
    saveDeletedLayerGoalId(layerGoalId)
  }

  const submitDeleteLayer = () => {
    deleteLayerGoal(deletedLayerGoalId);
    setShowDeleteLayerGoalModal(false)
  }

  const hideDeleteLayerGoalModal = () => {
    setShowDeleteLayerGoalModal(false);
  }

  const renderGoalCategories = (goalCategory: any) => {
    const CategoryIcon = RedLayerIcons[goalCategory.icon];
    const laterSelected = redLayer?.layer_goals.find((i: any) => i.category.id === goalCategory.id);
    const isActive = (categoryModal?.id === goalCategory.id);
    return (
      <div
        className={`flex items-center w-28 h-28 pointer-event-auto ${isActive ? "mb-[230px]" : ""}`}
        key={goalCategory.id}
        data-target="goal-category"
      >
        <span className="flex items-center w-full h-full rounded-xl overflow-hidden" onClick={(e: any) => onSelect(goalCategory)}><CategoryIcon isActive={isActive || laterSelected} /></span>
        {isActive ? <GoalCategoryDetailModal openGoalModal={openGoalModal} data={goalCategory} /> : null}
      </div>
    );
  }
  const renderLayerGoals = (layerGoal: any) => {
    const openModal = () => {
      const data = {
        layerGoalId: layerGoal.id,
        layerGoalTitle: layerGoal.title,
        layerGoalMilestoneDefinition: layerGoal.milestoneDefinition,
        layerGoalDeadline: layerGoal.deadline,
        layerGoalDescription: layerGoal.description,
        layerResponsibles: layerGoal.responsible,
        goals: redLayer.goalCategoriesList.find((i: any) => i.id === layerGoal.category.id).goals,
        selectedGoals: layerGoal.goals.map((i: any) => i.id),
        categoryId: layerGoal.category.id
      }
      openGoalModal(data);
    }
    return <RedLayerGoal openGoalModal={openModal} deleteLayerGoal={onDeleteLayerGoal} key={layerGoal.id} layerGoal={layerGoal} />
  }

  if (redLayer?.id) return (
    <>
      <div className="p-5 pt-8 h-full ">
        <div className="w-4/5 m-auto text-center">
          <div
            className={classnames("overflow-hidden", {
              "h-[auto]": showFullDescription,
              "h-[63px]": !showFullDescription,
            })}
            dangerouslySetInnerHTML={{ __html: RED_LAYER_DESCRIPTION }} />
          <button onClick={() => setShowFullDescription(!showFullDescription)}>{showFullDescription ? "Vis mindre" : "Læs mere" }</button>
        </div>
        <div className="mt-8 flex justify-between space-x-12">
          <div className="goal-category-wrap w-1/2 rounded-lg flex flex-wrap gap-4">
            {redLayer?.goalCategoriesList?.map(renderGoalCategories) || null}
            <div className="flex items-center w-28 h-28 pointer-event-auto" />
            <div className="flex items-center w-28 h-28 pointer-event-auto" />
            <div className="flex items-center w-28 h-28 pointer-event-auto" />
            <div className="flex items-center w-28 h-28 pointer-event-auto" />
            <div className="flex items-center w-28 h-28 pointer-event-auto" />
            <div className="flex items-center w-28 h-28 pointer-event-auto" />
          </div>
          <div className="w-1/2 p-4 rounded-lg bg-redLayer text-white">
            <div className="text-2xl font-bold">Dine verdensmål</div>
            <div className="goal-category-wrap flex flex-wrap gap-4 mt-4">
              {redLayer?.layer_goals?.length ? redLayer.layer_goals.map(renderLayerGoals) : "Ingen lagmål"}
            </div>
          </div>
        </div>
      </div>
      {goalModla.visible ? <GoalModal
        setShowModal={closeGoalModal}
        onSubmit={onSubmitGoalModal}
        showModal={goalModla.visible}
        title={categoryModal.iconTitle}
        introduction={"Vælg delmål"}
        body={""}
        listtitle={""}
        liitems={null}
        inputLabel={"Overskrift"}
        textareaLabel={"Hvilken indsats er planlagt for delmålet"}
        SubmitButtonText={"Gem"}
        FormSubmitBtnDisabled={false}
        data={goalModla.data}
      /> : null}
      <ConfirmationModal
        setShowModal={hideDeleteLayerGoalModal}
        submitRed={submitDeleteLayer}
        submitGreen={hideDeleteLayerGoalModal}
        showModal={showDeleteLayerGoalModal}
        title={RED_SUB_GOAL_DELETE_CONFIRMATION_DATA.title}
        body={RED_SUB_GOAL_DELETE_CONFIRMATION_DATA.body}
        redBtnLabel={RED_SUB_GOAL_DELETE_CONFIRMATION_DATA.redBtnLabel}
        greenBtnLabel={RED_SUB_GOAL_DELETE_CONFIRMATION_DATA.greenBtnLabel}
      />
    </>
  );
  if (show404) return <NotFound404 />;
  return <div/>;
}

const mapState = (state: RootState) => ({
  redLayer: state.redLayer,
  userId: state.user.data?.id,
  deletedLayerGoalId: state.redLayer?.deletedLayerGoalId
});

const mapDispatch = (dispatch: Dispatch) => ({
  getRedLayer: dispatch.redLayer.getRedLayer,
  saveLayerGoal: dispatch.redLayer.saveLayerGoal,
  deleteLayerGoal: dispatch.redLayer.deleteLayerGoal,
  saveDeletedLayerGoalId: dispatch.redLayer.saveDeletedLayerGoalId
});

export default connect(mapState, mapDispatch)(RedLayerPage);
