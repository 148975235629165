import React, { FC } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../../store/store";

import cheveronRightIcon from "../../assets/icons/chevron-right.png";
import smallPlusIcon from "../../assets/icons/small-plus.png";
import { EMPTY_BUSINESS_PLAN_ROUTE, getBPUrl } from "../../store/constants/route-constants";

interface IBPModalPRops {
  isEditor: boolean;
  visible: boolean;
  items: {
    id: string;
    name: string;
  }[];
  close?: () => void;
  activeItem: any;
  duplicateBp?: (id: string) => void;
}

const TopRightModal: FC<IBPModalPRops> = ({
                                            isEditor,
                                            items,
                                            visible,
                                            close,
                                            activeItem,
                                            duplicateBp,
                                          }) => {

  return (
    <>
      <div
        className={`${
          !visible && "hidden"
        } w-[auto!important] max-w-md h-[auto!important] overflow-y-auto overflow-x-hidden absolute top-32 right-4 z-50 h-modal md:h-full drop-shadow-md`}
      >
        <div className="bg-secondBg rounded-lg shadow">
          <div className="max-h-[60vh] p-4 space-y-1 text-1xl font-medium overflow-y-auto">
            {items.length &&
              items.map((bp) => (
                <div
                  key={bp.id}
                  className={`flex items-center group justify-between p-1 m-0 rounded-lg hover:bg-hoverBg ${
                    activeItem === bp.id ? "bg-selectBg" : ""
                  }`}
                >
                  <Link
                    to={getBPUrl(bp.id)}
                    className={`cursor-pointer flex items-center ${
                      activeItem === bp.id ? "font-[700]" : "font-[600]"
                    }`}
                  >
                    {bp.name}
                    {/*<img*/}
                    {/*  className="transition-transform duration-200 group-hover:rotate-0 rotate-180 w-4 ml-3"*/}
                    {/*  // className="transition-transform duration-200 group-hover:rotate-0 rotate-180 w-4 ml-3 group-hover:absolute group-hover:right-5 group-hover:translate-x-full"*/}
                    {/*  src={cheveronRightIcon}*/}
                    {/*  alt=">"*/}
                    {/*/>*/}
                  </Link>
                  <div className="cursor-pointer opacity-0 group-hover:opacity-100 flex items-center justify-center transition-all duration-200">
                    <span className="ml-2">Duplikér</span>

                    <button
                      onClick={() => duplicateBp?.(bp.id)}
                      className="ml-3 flex items-center justify-center bg-gray-200 rounded-full w-6 h-6 hover:bg-greenLayer"
                    >
                      <img
                        className="w-4 h-4"
                        src={smallPlusIcon}
                        alt="Duplicate"
                      />
                    </button>
                  </div>
                </div>
              ))}
          </div>
          {isEditor && (
            <div className="p-4 text-1xl flex justify-end items-center gap-4">
              <Link
                to={EMPTY_BUSINESS_PLAN_ROUTE}
                className="flex items-center justify-center"
              >
                <div className="cursor-pointer flex items-center justify-center transition-all duration-200">
                  <span>Tilføj</span>
                  <span className="ml-2 w-9 h-9 flex items-center justify-center bg-gray-200 hover:bg-greenLayer rounded-full transition-colors duration-200">
                    <img src={smallPlusIcon} />
                  </span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div onClick={close} className="absolute inset-0 z-1" />
    </>
  );
};

const mapState = (state: RootState) => ({
  isEditor: state.user.isEditor,
});

export default connect(mapState)(TopRightModal);
