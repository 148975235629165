import classnames from "classnames";
import React, { SyntheticEvent, useRef } from "react";

export interface Props {
  LeftComponent: React.FC<any>;
  RightComponent: React.FC<any>;
  rightcomponentbackgroundcolor: string;
  showModal: boolean;
  setShowModal?: (e: SyntheticEvent) => void;
  innerPadding?: string;
}

export const Modal: React.FC<Props> = ({
  LeftComponent,
  RightComponent,
  showModal = false,
  innerPadding,
  setShowModal,
  ...props
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const onClikBackdrop = (e: any) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      if (setShowModal) {
        setShowModal(e);
      }
    }
  };

  return (
    <div
      id="backdrop"
      onClick={onClikBackdrop}
      className={classnames(
        `justify-center items-center flex z-50 overflow-y-auto fixed inset-0 bg-backdropBg ${
          showModal ? "" : "hidden"
        }`
      )}
    >
      <div
        // id="defaultModal"
        className="flex flex-row rounded-lg overflow-hidden w-[1200px] px-28 bg-grey-500"
        aria-hidden={true}
      >
        <div
          id="defaultModal"
          ref={modalRef}
          className="flex flex-row rounded-lg overflow-hidden w-[1200px]"
          aria-hidden={true}
        >
          <div className="flex-initial w-1/2 bg-white py-42 px-42 rounded-l-lg">
            <LeftComponent {...props} />
          </div>
          <div
            style={{ background: props?.rightcomponentbackgroundcolor }}
            className={classnames(
              `flex-initial w-1/2 ${
                innerPadding ? innerPadding : "py-42 px-42"
              } bg-gray-100 rounded-r-lg`
            )}
          >
            <RightComponent {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};
